.dot {
  animation: dot-blink 1s infinite;
  font-weight: 900;
  margin-left: 2px;
  margin-right: 2px;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
}
.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0%, 20% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  80%, 100% {
    opacity: 0.1;
  }
}
