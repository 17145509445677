<ion-card class="card dictation-list" style="margin-top: 12px;" *ngIf="dictations">
  <ion-card-header>
    <ion-card-title>
      <fa-icon [icon]="['fas', 'edit']"></fa-icon>
      {{ title | translate }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>
    <div class="ion-text-center" *ngIf="dictations?.length == 0" style="padding: 16px;">{{ 'No dictation' | translate}}</div>
    <ion-list id="dictation-list">
      <ion-item *ngFor="let dictation of viewDictations; let i = index">
        <ion-label [@move]="state" (@move.done)="onDone($event)">
          <ion-row>
            <ion-col class="heading">{{dictation.title}}</ion-col>
            <ion-col class="ion-text-right">{{ dictation.createdDate | date: 'yyyy-MM-dd' }}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col style="min-width: 120px;">
              <fa-icon [icon]="['fas', 'list-ol']"></fa-icon> {{ dictation | dictationQuestions }}
            </ion-col>
            <ion-col>
              <div class="ion-text-end">
                <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon> {{ dictation.totalAttempt }}
              </div>
            </ion-col>
            <ion-col class="recommended-col" *ngIf="source !== DictationSource.Select">
              <div class="ion-text-end">
                <fa-icon [icon]="['far', 'thumbs-up']"></fa-icon> {{dictation.totalRecommended}}
              </div>
              </ion-col>
          </ion-row>
          <ion-row class="suitable-student-row" *ngIf="dictation.suitableStudent">
            <ion-col class="col-label">{{ 'Suitable' | translate }}: {{ 'SuitableStudent.' + dictation.suitableStudent | translate }}</ion-col>
          </ion-row>
        </ion-label>
        <ion-button fill="outline" slot="end" (click)="navService.pushOpenDictation(dictation)">{{ 'ViewDictation' | translate }}</ion-button>
      </ion-item>
    </ion-list>
    <ion-row>
      <ion-col>
        <ion-button fill="clear" *ngIf="page > 0" color="secondary" (click)="newer()"><fa-icon [icon]="['fas', 'angle-left']"></fa-icon> {{ 'Newer' | translate }}</ion-button>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-button fill="clear" *ngIf="showOlder" color="secondary" (click)="older()">{{ 'Older' | translate }} <fa-icon [icon]="['fas', 'angle-right']"></fa-icon></ion-button>
      </ion-col>
    </ion-row>
    <ion-row style="border-top: 1px solid #c8c7cc;">
      <ion-col class="ion-text-right">
        <ion-button fill="clear" *ngIf="showCreateButton" (click)="navService.editDictation()"><fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp;{{ 'Create Dictation' | translate }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-card-content>
</ion-card>
