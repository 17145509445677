<ion-header>
  <ion-toolbar>
    <ion-button fill="clear" slot="start" (click)="this.modalController.dismiss()"><fa-icon [icon]="['fas', 'times']"></fa-icon></ion-button>

    <ion-title>{{ 'SelectedVocabulary' | translate:{number: selectedVocabs.size} }}</ion-title>

    <ion-button fill="clear" slot="end" (click)="createExercise()" *ngIf="selectedVocabs?.size > 0"><fa-icon [icon]="['fas', 'check']"></fa-icon></ion-button>

  </ion-toolbar>
</ion-header>
<ion-content style="height: 100%">
  <form *ngIf="inputForm" [formGroup]="inputForm" novalidate>
    <ion-list>
      <ion-item>
        <ion-input type="text" label="{{'Title'|translate}}:" formControlName="title"></ion-input>
      </ion-item>
      <ion-item *ngIf="title.invalid && (title.dirty || title.touched)" class="error-text">
        <ion-label *ngIf="title.errors.required">{{'Title is required'|translate}}</ion-label>
        <ion-label *ngIf="title.errors.minlength">{{'Title must be at least 5 characters long'|translate}}</ion-label>
        <ion-label *ngIf="title.errors.maxlength">{{'Title cannot over 50 characters long'|translate}}</ion-label>
      </ion-item>

      <ion-item *ngFor="let vocab of showingVocabs; let i = index; trackBy: vocabTrackById"
                button (click)="selectVocab(vocab)"
                [class.highlight]="selectedVocabs.has(vocab.id.word)">
        <ion-label>
          <ion-row>
            <ion-col class="heading word">{{vocab.id.word}}</ion-col>
            <ion-col class="ion-text-right">
              <span class="green-text"><fa-icon [icon]="['fas', 'check']"></fa-icon> {{vocab.correct}}</span>
              /
              <span class="red"><fa-icon [icon]="['fas', 'times']"></fa-icon> {{vocab.wrong}}</span>
            </ion-col>
          </ion-row>
        </ion-label>
      </ion-item>
    </ion-list>
  </form>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
