<ion-card class="card list" style="margin-top: 12px;">
  <ion-card-header>
    <ion-card-title>
      <fa-icon [icon]="['fas', 'edit']"></fa-icon>
      {{ 'LastTimesPractice' | translate: {times: histories.length} }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>
    <ion-list>
      <ion-item *ngFor="let h of histories; let i = index">
        <ion-label>
          <ion-row>
            <ion-col class="heading">{{getDictationTitle(h.historyJSON)}}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col>{{ h.createdDate | date: 'yyyy-MM-dd' }}</ion-col>
            <ion-col>
              <span class="green-text"><fa-icon [icon]="['fas', 'check']"></fa-icon> {{h.correct}} ({{h.correct / (h.correct + h.wrong) * 100 | number:'1.0-0'}}%)</span>
              /
              <span class="red"><fa-icon [icon]="['fas', 'times']"></fa-icon> {{h.wrong}} ({{h.wrong / (h.correct + h.wrong) * 100 | number:'1.0-0'}}%)</span>
            </ion-col>
          </ion-row>
        </ion-label>
        <ion-button fill="outline" slot="end" (click)="openHistory(h)">{{ 'Detail' | translate }}</ion-button>
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-card>
