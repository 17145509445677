.vocab-history-list {
  ion-item {
    padding-left: 0px;
    color: #475062;
    line-height: 1.2;
    --min-height: 12px;
  }
}

ion-col {
  padding: 0px 5px 0px 5px;
}

.heading {
  color: #000;
}
