<ion-list>
  <ion-item>
    <ion-toggle #caseSensitive (ionChange)="optionChanged($event, caseSensitiveKey)">
      <fa-icon [icon]="['fas', 'font']"></fa-icon> {{'Case Sensitive'|translate}}
    </ion-toggle>
  </ion-item>
  <ion-item>
    <ion-toggle #checkPunctuation (ionChange)="optionChanged($event, checkPunctuationKey)">
      <fa-icon [icon]="['fas', 'question']"></fa-icon> {{'Check Punctuation'|translate}}
    </ion-toggle>
  </ion-item>
  <ion-item>
    <ion-toggle #speakPunctuation (ionChange)="optionChanged($event, speakPunctuationKey)">
      <fa-icon [icon]="['fas', 'volume-low']"></fa-icon> {{'Speak Punctuation'|translate}}
    </ion-toggle>
  </ion-item>
</ion-list>
