ion-card {
  height: 274px;
}

.button {
  font-size: 1.4rem;
}

.image {
  width: auto;
  max-height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 4px;
}

.grey-text {
  color: gray;
}
