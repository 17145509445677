ion-button {
  font-size: 32px;
  width: 44px;
  height: 44px;
  font-weight: bold;
  margin: 4px;
  --padding-start: 8px;
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-end: 8px;
}
