<div style="padding-top: 8px;">
  <ion-card class="card" *ngIf="histories && histories.length > 0">
    <ion-card-content>
      <ion-list>
        <ion-item *ngFor="let history of histories; let i = index">
          <ion-button fill="clear" slot="start" style="margin: 4px 0.3rem 4px 0;" (click)="speechService.speak(history.question)">
            <fa-icon [icon]="['fas', 'headphones']"></fa-icon>
          </ion-button>
          <!--<ion-badge item-start style="margin: 0px; margin-right: 4px;">{{ histories.length - i }}</ion-badge>-->
          <ion-label>
            <p><span *ngFor="let word of history.questionSegments; let j = index"
                     [class.green-text]="history.isCorrect[j]"
                     [class.red]="!history.isCorrect[j]">{{word}}&nbsp;</span></p>
            <p>{{history.answer}} &nbsp;</p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</div>
