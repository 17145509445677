import * as i0 from '@angular/core';
import { Injectable, Directive, Input, Component, inject, Optional, HostBinding, NgModule } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { dom, config, icon, parse, counter, text } from '@fortawesome/fontawesome-svg-core';
import * as i2 from '@angular/platform-browser';
const faWarnIfIconDefinitionMissing = iconSpec => {
  throw new Error(`Could not find icon with iconName=${iconSpec.iconName} and prefix=${iconSpec.prefix} in the icon library.`);
};
const faWarnIfIconSpecMissing = () => {
  throw new Error('Property `icon` is required for `fa-icon`/`fa-duotone-icon` components.');
};
const isKnownRotateValue = rotate => rotate != null && (rotate === 90 || rotate === 180 || rotate === 270 || rotate === '90' || rotate === '180' || rotate === '270');
/**
 * Fontawesome class list.
 * Returns classes array by props.
 */
const faClassList = props => {
  const knownRotateValue = isKnownRotateValue(props.rotate);
  const classes = {
    [`fa-${props.animation}`]: props.animation != null && !props.animation.startsWith('spin'),
    'fa-spin': props.animation === 'spin' || props.animation === 'spin-reverse',
    'fa-spin-pulse': props.animation === 'spin-pulse' || props.animation === 'spin-pulse-reverse',
    'fa-spin-reverse': props.animation === 'spin-reverse' || props.animation === 'spin-pulse-reverse',
    // According to https://fontawesome.com/docs/web/style/animate#spin fa-pulse
    // class is deprecated, remove the below line when Font Awesome 5 support
    // is dropped.
    'fa-pulse': props.animation === 'spin-pulse' || props.animation === 'spin-pulse-reverse',
    'fa-fw': props.fixedWidth,
    'fa-border': props.border,
    'fa-inverse': props.inverse,
    'fa-layers-counter': props.counter,
    'fa-flip-horizontal': props.flip === 'horizontal' || props.flip === 'both',
    'fa-flip-vertical': props.flip === 'vertical' || props.flip === 'both',
    [`fa-${props.size}`]: props.size !== null,
    [`fa-rotate-${props.rotate}`]: knownRotateValue,
    'fa-rotate-by': props.rotate != null && !knownRotateValue,
    [`fa-pull-${props.pull}`]: props.pull !== null,
    [`fa-stack-${props.stackItemSize}`]: props.stackItemSize != null
  };
  return Object.keys(classes).map(key => classes[key] ? key : null).filter(key => key);
};
const cssInserted = new WeakSet();
const autoCssId = 'fa-auto-css';
/**
 * Ensure that Font Awesome CSS is inserted into the page.
 *
 * SVG Core has the same logic to insert the same styles into the page, however
 * it's not aware of Angular SSR and therefore styles won't be added in that
 * context leading to https://github.com/FortAwesome/angular-fontawesome/issues/48.
 * That's why the same logic is duplicated here.
 *
 * @param document - Document.
 * @param config - Font Awesome configuration.
 */
function ensureCss(document, config) {
  if (!config.autoAddCss) {
    return;
  }
  if (cssInserted.has(document)) {
    return;
  }
  // Prevent adding the same styles again after hydration.
  if (document.getElementById(autoCssId) != null) {
    config.autoAddCss = false;
    cssInserted.add(document);
    return;
  }
  const style = document.createElement('style');
  style.setAttribute('type', 'text/css');
  style.setAttribute('id', autoCssId);
  style.innerHTML = dom.css();
  const headChildren = document.head.childNodes;
  let beforeChild = null;
  for (let i = headChildren.length - 1; i > -1; i--) {
    const child = headChildren[i];
    const tagName = child.nodeName.toUpperCase();
    if (['STYLE', 'LINK'].indexOf(tagName) > -1) {
      beforeChild = child;
    }
  }
  document.head.insertBefore(style, beforeChild);
  // Prevent SVG Core from adding the same styles.
  //
  // As the logic is present in two places and SVG Core is not aware about
  // this library, it may lead to styles being added twice. This can only
  // occur when icon is rendered by SVG Core before the Angular component
  // and should not have any significant negative impact. This is a rare
  // use case, and it's tricky to prevent, so we accept this behavior. Consumer
  // can choose to disable `FaConfig.autoAddCss` and add styles manually to
  // prevent this from happening.
  config.autoAddCss = false;
  cssInserted.add(document);
}

/**
 * Returns if is IconLookup or not.
 */
const isIconLookup = i => i.prefix !== undefined && i.iconName !== undefined;

/**
 * Normalizing icon spec.
 */
const faNormalizeIconSpec = (iconSpec, defaultPrefix) => {
  if (isIconLookup(iconSpec)) {
    return iconSpec;
  }
  if (Array.isArray(iconSpec) && iconSpec.length === 2) {
    return {
      prefix: iconSpec[0],
      iconName: iconSpec[1]
    };
  }
  return {
    prefix: defaultPrefix,
    iconName: iconSpec
  };
};
class FaConfig {
  constructor() {
    /**
     * Default prefix to use, when one is not provided with the icon name.
     *
     * @default 'fas'
     */
    this.defaultPrefix = 'fas';
    /**
     * Provides a fallback icon to use whilst main icon is being loaded asynchronously.
     * When value is null, then fa-icon component will throw an error if icon input is missing.
     * When value is not null, then the provided icon will be used as a fallback icon if icon input is missing.
     *
     * @default null
     */
    this.fallbackIcon = null;
    this._autoAddCss = true;
  }
  /**
   * Automatically add Font Awesome styles to the document when icon is rendered.
   *
   * For the majority of the cases the automatically added CSS is sufficient,
   * please refer to the linked guide for more information on when to disable
   * this feature.
   *
   * @see {@link: https://github.com/FortAwesome/angular-fontawesome/blob/main/docs/guide/adding-css.md}
   * @default true
   */
  set autoAddCss(value) {
    config.autoAddCss = value;
    this._autoAddCss = value;
  }
  get autoAddCss() {
    return this._autoAddCss;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaConfig,
      deps: [],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaConfig,
      providedIn: 'root'
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FaConfig,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
class FaIconLibrary {
  constructor() {
    this.definitions = {};
  }
  addIcons(...icons) {
    for (const icon of icons) {
      if (!(icon.prefix in this.definitions)) {
        this.definitions[icon.prefix] = {};
      }
      this.definitions[icon.prefix][icon.iconName] = icon;
      for (const alias of icon.icon[2]) {
        if (typeof alias === 'string') {
          this.definitions[icon.prefix][alias] = icon;
        }
      }
    }
  }
  addIconPacks(...packs) {
    for (const pack of packs) {
      const icons = Object.keys(pack).map(key => pack[key]);
      this.addIcons(...icons);
    }
  }
  getIconDefinition(prefix, name) {
    if (prefix in this.definitions && name in this.definitions[prefix]) {
      return this.definitions[prefix][name];
    }
    return null;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaIconLibrary,
      deps: [],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaIconLibrary,
      providedIn: 'root'
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FaIconLibrary,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
class FaStackItemSizeDirective {
  constructor() {
    /**
     * Specify whether icon inside {@link FaStackComponent} should be rendered in
     * regular size (1x) or as a larger icon (2x).
     */
    this.stackItemSize = '1x';
  }
  ngOnChanges(changes) {
    if ('size' in changes) {
      throw new Error('fa-icon is not allowed to customize size when used inside fa-stack. ' + 'Set size on the enclosing fa-stack instead: <fa-stack size="4x">...</fa-stack>.');
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaStackItemSizeDirective,
      deps: [],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "19.0.0",
      type: FaStackItemSizeDirective,
      isStandalone: true,
      selector: "fa-icon[stackItemSize],fa-duotone-icon[stackItemSize]",
      inputs: {
        stackItemSize: "stackItemSize",
        size: "size"
      },
      usesOnChanges: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FaStackItemSizeDirective,
  decorators: [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: 'fa-icon[stackItemSize],fa-duotone-icon[stackItemSize]'
    }]
  }],
  propDecorators: {
    stackItemSize: [{
      type: Input
    }],
    size: [{
      type: Input
    }]
  }
});
class FaStackComponent {
  constructor(renderer, elementRef) {
    this.renderer = renderer;
    this.elementRef = elementRef;
  }
  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'fa-stack');
  }
  ngOnChanges(changes) {
    if ('size' in changes) {
      if (changes.size.currentValue != null) {
        this.renderer.addClass(this.elementRef.nativeElement, `fa-${changes.size.currentValue}`);
      }
      if (changes.size.previousValue != null) {
        this.renderer.removeClass(this.elementRef.nativeElement, `fa-${changes.size.previousValue}`);
      }
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaStackComponent,
      deps: [{
        token: i0.Renderer2
      }, {
        token: i0.ElementRef
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "19.0.0",
      type: FaStackComponent,
      isStandalone: true,
      selector: "fa-stack",
      inputs: {
        size: "size"
      },
      usesOnChanges: true,
      ngImport: i0,
      template: `<ng-content></ng-content>`,
      isInline: true
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FaStackComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'fa-stack',
      template: `<ng-content></ng-content>`
    }]
  }],
  ctorParameters: () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }],
  propDecorators: {
    size: [{
      type: Input
    }]
  }
});
class FaIconComponent {
  constructor(sanitizer, config, iconLibrary, stackItem, stack) {
    this.sanitizer = sanitizer;
    this.config = config;
    this.iconLibrary = iconLibrary;
    this.stackItem = stackItem;
    this.document = inject(DOCUMENT);
    if (stack != null && stackItem == null) {
      console.error('FontAwesome: fa-icon and fa-duotone-icon elements must specify stackItemSize attribute when wrapped into ' + 'fa-stack. Example: <fa-icon stackItemSize="2x"></fa-icon>.');
    }
  }
  ngOnChanges(changes) {
    if (this.icon == null && this.config.fallbackIcon == null) {
      faWarnIfIconSpecMissing();
      return;
    }
    if (changes) {
      const iconDefinition = this.findIconDefinition(this.icon ?? this.config.fallbackIcon);
      if (iconDefinition != null) {
        const params = this.buildParams();
        ensureCss(this.document, this.config);
        const renderedIcon = icon(iconDefinition, params);
        this.renderedIconHTML = this.sanitizer.bypassSecurityTrustHtml(renderedIcon.html.join('\n'));
      }
    }
  }
  /**
   * Programmatically trigger rendering of the icon.
   *
   * This method is useful, when creating {@link FaIconComponent} dynamically or
   * changing its inputs programmatically as in these cases icon won't be
   * re-rendered automatically.
   */
  render() {
    this.ngOnChanges({});
  }
  findIconDefinition(i) {
    const lookup = faNormalizeIconSpec(i, this.config.defaultPrefix);
    if ('icon' in lookup) {
      return lookup;
    }
    const definition = this.iconLibrary.getIconDefinition(lookup.prefix, lookup.iconName);
    if (definition != null) {
      return definition;
    }
    faWarnIfIconDefinitionMissing(lookup);
    return null;
  }
  buildParams() {
    const classOpts = {
      flip: this.flip,
      animation: this.animation,
      border: this.border,
      inverse: this.inverse,
      size: this.size || null,
      pull: this.pull || null,
      rotate: this.rotate || null,
      fixedWidth: typeof this.fixedWidth === 'boolean' ? this.fixedWidth : this.config.fixedWidth,
      stackItemSize: this.stackItem != null ? this.stackItem.stackItemSize : null
    };
    const parsedTransform = typeof this.transform === 'string' ? parse.transform(this.transform) : this.transform;
    const styles = {};
    if (classOpts.rotate != null && !isKnownRotateValue(classOpts.rotate)) {
      styles['--fa-rotate-angle'] = `${classOpts.rotate}`;
    }
    return {
      title: this.title,
      transform: parsedTransform,
      classes: faClassList(classOpts),
      mask: this.mask != null ? this.findIconDefinition(this.mask) : null,
      symbol: this.symbol,
      attributes: {
        role: this.a11yRole
      },
      styles
    };
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaIconComponent,
      deps: [{
        token: i2.DomSanitizer
      }, {
        token: FaConfig
      }, {
        token: FaIconLibrary
      }, {
        token: FaStackItemSizeDirective,
        optional: true
      }, {
        token: FaStackComponent,
        optional: true
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "19.0.0",
      type: FaIconComponent,
      isStandalone: true,
      selector: "fa-icon",
      inputs: {
        icon: "icon",
        title: "title",
        animation: "animation",
        mask: "mask",
        flip: "flip",
        size: "size",
        pull: "pull",
        border: "border",
        inverse: "inverse",
        symbol: "symbol",
        rotate: "rotate",
        fixedWidth: "fixedWidth",
        transform: "transform",
        a11yRole: "a11yRole"
      },
      host: {
        properties: {
          "attr.title": "title",
          "innerHTML": "this.renderedIconHTML"
        },
        classAttribute: "ng-fa-icon"
      },
      usesOnChanges: true,
      ngImport: i0,
      template: ``,
      isInline: true
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FaIconComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'fa-icon',
      template: ``,
      host: {
        class: 'ng-fa-icon',
        '[attr.title]': 'title'
      }
    }]
  }],
  ctorParameters: () => [{
    type: i2.DomSanitizer
  }, {
    type: FaConfig
  }, {
    type: FaIconLibrary
  }, {
    type: FaStackItemSizeDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: FaStackComponent,
    decorators: [{
      type: Optional
    }]
  }],
  propDecorators: {
    icon: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    mask: [{
      type: Input
    }],
    flip: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    pull: [{
      type: Input
    }],
    border: [{
      type: Input
    }],
    inverse: [{
      type: Input
    }],
    symbol: [{
      type: Input
    }],
    rotate: [{
      type: Input
    }],
    fixedWidth: [{
      type: Input
    }],
    transform: [{
      type: Input
    }],
    a11yRole: [{
      type: Input
    }],
    renderedIconHTML: [{
      type: HostBinding,
      args: ['innerHTML']
    }]
  }
});
class FaDuotoneIconComponent extends FaIconComponent {
  findIconDefinition(i) {
    const definition = super.findIconDefinition(i);
    if (definition != null && !Array.isArray(definition.icon[4])) {
      throw new Error('The specified icon does not appear to be a Duotone icon. ' + 'Check that you specified the correct style: ' + `<fa-duotone-icon [icon]="['fad', '${definition.iconName}']"></fa-duotone-icon> ` + `or use: <fa-icon icon="${definition.iconName}"></fa-icon> instead.`);
    }
    return definition;
  }
  buildParams() {
    const params = super.buildParams();
    if (this.swapOpacity === true || this.swapOpacity === 'true') {
      if (Array.isArray(params.classes)) {
        params.classes.push('fa-swap-opacity');
      } else if (typeof params.classes === 'string') {
        params.classes = [params.classes, 'fa-swap-opacity'];
      } else {
        params.classes = ['fa-swap-opacity'];
      }
    }
    if (params.styles == null) {
      params.styles = {};
    }
    if (this.primaryOpacity != null) {
      params.styles['--fa-primary-opacity'] = this.primaryOpacity.toString();
    }
    if (this.secondaryOpacity != null) {
      params.styles['--fa-secondary-opacity'] = this.secondaryOpacity.toString();
    }
    if (this.primaryColor != null) {
      params.styles['--fa-primary-color'] = this.primaryColor;
    }
    if (this.secondaryColor != null) {
      params.styles['--fa-secondary-color'] = this.secondaryColor;
    }
    return params;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaDuotoneIconComponent,
      deps: null,
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "19.0.0",
      type: FaDuotoneIconComponent,
      isStandalone: true,
      selector: "fa-duotone-icon",
      inputs: {
        swapOpacity: "swapOpacity",
        primaryOpacity: "primaryOpacity",
        secondaryOpacity: "secondaryOpacity",
        primaryColor: "primaryColor",
        secondaryColor: "secondaryColor"
      },
      usesInheritance: true,
      ngImport: i0,
      template: ``,
      isInline: true
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FaDuotoneIconComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'fa-duotone-icon',
      template: ``
    }]
  }],
  propDecorators: {
    swapOpacity: [{
      type: Input
    }],
    primaryOpacity: [{
      type: Input
    }],
    secondaryOpacity: [{
      type: Input
    }],
    primaryColor: [{
      type: Input
    }],
    secondaryColor: [{
      type: Input
    }]
  }
});

/**
 * Warns if parent component not existing.
 */
const faWarnIfParentNotExist = (parent, parentName, childName) => {
  if (!parent) {
    throw new Error(`${childName} should be used as child of ${parentName} only.`);
  }
};

/**
 * Fontawesome layers.
 */
class FaLayersComponent {
  constructor(renderer, elementRef, config) {
    this.renderer = renderer;
    this.elementRef = elementRef;
    this.config = config;
    this.document = inject(DOCUMENT);
  }
  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'fa-layers');
    ensureCss(this.document, this.config);
    this.fixedWidth = typeof this.fixedWidth === 'boolean' ? this.fixedWidth : this.config.fixedWidth;
  }
  ngOnChanges(changes) {
    if ('size' in changes) {
      if (changes.size.currentValue != null) {
        this.renderer.addClass(this.elementRef.nativeElement, `fa-${changes.size.currentValue}`);
      }
      if (changes.size.previousValue != null) {
        this.renderer.removeClass(this.elementRef.nativeElement, `fa-${changes.size.previousValue}`);
      }
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaLayersComponent,
      deps: [{
        token: i0.Renderer2
      }, {
        token: i0.ElementRef
      }, {
        token: FaConfig
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "19.0.0",
      type: FaLayersComponent,
      isStandalone: true,
      selector: "fa-layers",
      inputs: {
        size: "size",
        fixedWidth: "fixedWidth"
      },
      host: {
        properties: {
          "class.fa-fw": "this.fixedWidth"
        }
      },
      usesOnChanges: true,
      ngImport: i0,
      template: `<ng-content></ng-content>`,
      isInline: true
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FaLayersComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'fa-layers',
      template: `<ng-content></ng-content>`
    }]
  }],
  ctorParameters: () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: FaConfig
  }],
  propDecorators: {
    size: [{
      type: Input
    }],
    fixedWidth: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['class.fa-fw']
    }]
  }
});
class FaLayersCounterComponent {
  constructor(parent, sanitizer) {
    this.parent = parent;
    this.sanitizer = sanitizer;
    this.document = inject(DOCUMENT);
    this.config = inject(FaConfig);
    faWarnIfParentNotExist(this.parent, 'FaLayersComponent', this.constructor.name);
  }
  ngOnChanges(changes) {
    if (changes) {
      const params = this.buildParams();
      this.updateContent(params);
    }
  }
  buildParams() {
    return {
      title: this.title,
      classes: this.position != null ? [`fa-layers-${this.position}`] : undefined
    };
  }
  updateContent(params) {
    ensureCss(this.document, this.config);
    this.renderedHTML = this.sanitizer.bypassSecurityTrustHtml(counter(this.content || '', params).html.join(''));
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaLayersCounterComponent,
      deps: [{
        token: FaLayersComponent,
        optional: true
      }, {
        token: i2.DomSanitizer
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "19.0.0",
      type: FaLayersCounterComponent,
      isStandalone: true,
      selector: "fa-layers-counter",
      inputs: {
        content: "content",
        title: "title",
        position: "position"
      },
      host: {
        properties: {
          "innerHTML": "this.renderedHTML"
        },
        classAttribute: "ng-fa-layers-counter"
      },
      usesOnChanges: true,
      ngImport: i0,
      template: '',
      isInline: true
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FaLayersCounterComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'fa-layers-counter',
      template: '',
      host: {
        class: 'ng-fa-layers-counter'
      }
    }]
  }],
  ctorParameters: () => [{
    type: FaLayersComponent,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i2.DomSanitizer
  }],
  propDecorators: {
    content: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    renderedHTML: [{
      type: HostBinding,
      args: ['innerHTML']
    }]
  }
});
class FaLayersTextComponent {
  constructor(parent, sanitizer) {
    this.parent = parent;
    this.sanitizer = sanitizer;
    this.document = inject(DOCUMENT);
    this.config = inject(FaConfig);
    faWarnIfParentNotExist(this.parent, 'FaLayersComponent', this.constructor.name);
  }
  ngOnChanges(changes) {
    if (changes) {
      const params = this.buildParams();
      this.updateContent(params);
    }
  }
  /**
   * Updating params by component props.
   */
  buildParams() {
    const classOpts = {
      flip: this.flip,
      border: this.border,
      inverse: this.inverse,
      size: this.size || null,
      pull: this.pull || null,
      rotate: this.rotate || null,
      fixedWidth: this.fixedWidth
    };
    const parsedTransform = typeof this.transform === 'string' ? parse.transform(this.transform) : this.transform;
    const styles = {};
    if (classOpts.rotate != null && !isKnownRotateValue(classOpts.rotate)) {
      styles['--fa-rotate-angle'] = `${classOpts.rotate}`;
    }
    return {
      transform: parsedTransform,
      classes: faClassList(classOpts),
      title: this.title,
      styles
    };
  }
  updateContent(params) {
    ensureCss(this.document, this.config);
    this.renderedHTML = this.sanitizer.bypassSecurityTrustHtml(text(this.content || '', params).html.join('\n'));
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FaLayersTextComponent,
      deps: [{
        token: FaLayersComponent,
        optional: true
      }, {
        token: i2.DomSanitizer
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "19.0.0",
      type: FaLayersTextComponent,
      isStandalone: true,
      selector: "fa-layers-text",
      inputs: {
        content: "content",
        title: "title",
        flip: "flip",
        size: "size",
        pull: "pull",
        border: "border",
        inverse: "inverse",
        rotate: "rotate",
        fixedWidth: "fixedWidth",
        transform: "transform"
      },
      host: {
        properties: {
          "innerHTML": "this.renderedHTML"
        },
        classAttribute: "ng-fa-layers-text"
      },
      usesOnChanges: true,
      ngImport: i0,
      template: '',
      isInline: true
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FaLayersTextComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'fa-layers-text',
      template: '',
      host: {
        class: 'ng-fa-layers-text'
      }
    }]
  }],
  ctorParameters: () => [{
    type: FaLayersComponent,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i2.DomSanitizer
  }],
  propDecorators: {
    content: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    flip: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    pull: [{
      type: Input
    }],
    border: [{
      type: Input
    }],
    inverse: [{
      type: Input
    }],
    rotate: [{
      type: Input
    }],
    fixedWidth: [{
      type: Input
    }],
    transform: [{
      type: Input
    }],
    renderedHTML: [{
      type: HostBinding,
      args: ['innerHTML']
    }]
  }
});
class FontAwesomeModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FontAwesomeModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FontAwesomeModule,
      imports: [FaIconComponent, FaDuotoneIconComponent, FaLayersComponent, FaLayersTextComponent, FaLayersCounterComponent, FaStackComponent, FaStackItemSizeDirective],
      exports: [FaIconComponent, FaDuotoneIconComponent, FaLayersComponent, FaLayersTextComponent, FaLayersCounterComponent, FaStackComponent, FaStackItemSizeDirective]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "19.0.0",
      ngImport: i0,
      type: FontAwesomeModule
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.0",
  ngImport: i0,
  type: FontAwesomeModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [FaIconComponent, FaDuotoneIconComponent, FaLayersComponent, FaLayersTextComponent, FaLayersCounterComponent, FaStackComponent, FaStackItemSizeDirective],
      exports: [FaIconComponent, FaDuotoneIconComponent, FaLayersComponent, FaLayersTextComponent, FaLayersCounterComponent, FaStackComponent, FaStackItemSizeDirective]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { FaConfig, FaDuotoneIconComponent, FaIconComponent, FaIconLibrary, FaLayersComponent, FaLayersCounterComponent, FaLayersTextComponent, FaStackComponent, FaStackItemSizeDirective, FontAwesomeModule };
