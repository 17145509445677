span {
  font-size: 36px;
  margin: 2px;
  color: var(--ion-color-medium-shade);
  display: inline-block;
}

.blinking {
  font-weight: 900;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: var(--ion-color-dark-tint);
  }
}
