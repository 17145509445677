<div class="footer">
  <ion-row style="padding-left: 1rem; padding-right: 1rem;" *ngIf="!appService.isApp()">
    <ion-col size="6" size-sm="6" size-md="3" size-lg="3">
      <h5><fa-icon [icon]="['fas', 'info-circle']"></fa-icon> {{'About Us'|translate}}</h5>
      <hr>
      <p>{{'About_Us_Content'|translate}}</p>
      <a href="mailto: contactfunfunspell@gmail.com"><fa-icon class="square-button" [icon]="['fas', 'envelope']"></fa-icon></a>
      <a (click)="navigationService.openContactUs()"><fa-icon class="square-button" [icon]="['fas', 'question-circle']"></fa-icon></a>
    </ion-col>
    <ion-col size="6" size-sm="6" size-md="3" size-lg="3">
      <h5><fa-icon [icon]="['fas', 'link']"></fa-icon> {{'Links'|translate}}</h5>
      <hr>
      <ul>
        <li><a (click)="navigationService.openHomePage()">{{'Home'|translate}}</a></li>
      </ul>
      <h6>{{'Dictation'|translate}}</h6>
      <ul>
        <li><a (click)="navigationService.editDictation(null, 'Start')">{{'Menu_Start'|translate}}</a></li>
        <li><a (click)="navigationService.editDictation()">{{'Menu_Create'|translate}}</a></li>
        <li><a (click)="navigationService.openSearchDictation()">{{'Menu_Search'|translate}}</a></li>
      </ul>
      <h6>{{'Vocabulary Practice'|translate}}</h6>
      <ul>
        <li><a (click)="navigationService.openVocabularyStarter()">{{'Menu_Start'|translate}}</a></li>
        <li><a *ngIf="authService.isAuthenticated()" (click)="navigationService.openMemberHome('vocabulary')">{{'My Vocabulary'|translate}}</a></li>
      </ul>
    </ion-col>
    <ion-col size="6" size-sm="6" size-md="3" size-lg="3">
      <h5><fa-icon [icon]="['fas', 'user-circle']"></fa-icon> {{'Account'|translate}}</h5>
      <hr>
      <ul style="padding-inline-start: 0px;">
        <li><a (click)="authService.signUp()">{{'Sign Up'|translate}}</a></li>
        <li><a (click)="authService.login()">{{'Login'|translate}}</a></li>
        <li><a *ngIf="authService.isAuthenticated()" (click)="navigationService.openAccountPage()">{{'My Account'|translate}}</a></li>
      </ul>
      <h5><fa-icon [icon]="['fas', 'language']"></fa-icon> {{'Language'|translate}}</h5>
      <hr>
      <p>
        <span class="square-button" (click)="navigationService.changeLanguage('en')"><a>Eng</a></span>
        <span class="square-button" (click)="navigationService.changeLanguage('zh-Hant')"><a>繁</a></span>
        <span class="square-button" (click)="navigationService.changeLanguage('zh-Hans')"><a>简</a></span>
      </p>
    </ion-col>
    <ion-col size="6" size-sm="6" size-md="3" size-lg="3">
      <h5><fa-icon [icon]="['fas', 'mobile-alt']"></fa-icon> {{'Mobile App'|translate}}</h5>
      <hr>
      <p>
        <a href="https://apps.apple.com/us/app/funfunspell-english-dictation/id1364341686?itsct=apps_box_badge&amp;itscg=30200" style="display: inline-block; border-radius: 13px; width: auto; height: 44px;"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1522800000" alt="Download on the App Store" style="border-radius: 13px; width: auto; height: 44px;"></a>
      </p>
      <p>
        <a href="https://play.google.com/store/apps/details?id=com.esl.ionic" target="_blank" rel="noopener" aria-label="Get app from google play store">
          <img class="appImage" src="assets/img/google-play-badge.png" alt="google play store" />
        </a>
      </p>
    </ion-col>
  </ion-row>
  <div>
    <ion-img src="assets/img/footer_hd.png" alt="footer" style="width:100%"></ion-img>
  </div>
  <ion-row>
    <ion-col style="text-align: left;">Developing by Timmy Wong</ion-col>
    <ion-col class="ion-text-right">{{ version }} © 2008-2025 funfunspell.com</ion-col>
  </ion-row>
</div>
