{
  "name": "esl-ionic",
  "version": "9.7.2",
  "author": "thcathy",
  "homepage": "https://www.funfunspell.com",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test --browsers=ChromeWithAutoplay",
    "test-ci": "ng test --watch=false --browsers=ChromeHeadlessCI",
    "test-dev": "ng test --browsers=ChromeHeadlessCI",
    "lint": "ng lint",
    "e2e": "ng e2e --configuration=test",
    "e2e-ci": "ng e2e --configuration=test --protractor-config=e2e/protractor-ci.conf.js",
    "uat": "protractor e2e/protractor.conf.js"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.1",
    "@angular/common": "^19.2.1",
    "@angular/core": "^19.2.1",
    "@angular/forms": "^19.2.1",
    "@angular/platform-browser": "^19.2.1",
    "@angular/platform-browser-dynamic": "^19.2.1",
    "@angular/router": "^19.2.1",
    "@angular/service-worker": "^19.2.1",
    "@auth0/auth0-angular": "^2.2.3",
    "@capacitor-community/text-to-speech": "^6.0.0",
    "@capacitor/android": "^7.0.0",
    "@capacitor/app": "^7.0.0",
    "@capacitor/browser": "^7.0.0",
    "@capacitor/core": "^7.0.0",
    "@capacitor/haptics": "^7.0.0",
    "@capacitor/ios": "^7.0.0",
    "@capacitor/keyboard": "^7.0.0",
    "@capacitor/share": "^7.0.0",
    "@capacitor/splash-screen": "^7.0.0",
    "@capacitor/status-bar": "^7.0.0",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@fortawesome/fontawesome-svg-core": "^6.1.2",
    "@fortawesome/free-brands-svg-icons": "^6.1.2",
    "@fortawesome/free-regular-svg-icons": "^6.1.2",
    "@fortawesome/free-solid-svg-icons": "^6.1.2",
    "@ionic/angular": "^8.4.3",
    "@ionic/angular-server": "^8.4.3",
    "@ionic/core": "^8.4.3",
    "@ionic/lab": "^3.1.3",
    "@ionic/storage-angular": "^4.0.0",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "core-js": "^3.32.2",
    "firebase-tools": "^13.6.1",
    "g": "^2.0.1",
    "help": "3.0.2",
    "native-run": "^1.7.0",
    "ngx-logger": "^5.0.11",
    "rxjs": "^7.5.0",
    "tslib": "^2.4.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.1",
    "@angular/cli": "^19.2.1",
    "@angular/compiler": "^19.2.1",
    "@angular/compiler-cli": "^19.2.1",
    "@angular/language-service": "^19.2.1",
    "@capacitor/assets": "^3.0.5",
    "@capacitor/cli": "^7.0.0",
    "@ionic/angular-toolkit": "^12.1.1",
    "@types/jasmine": "^4.3.6",
    "@types/jasminewd2": "^2.0.11",
    "@types/node": "^18.17.19",
    "@typescript-eslint/eslint-plugin": "5.29.0",
    "@typescript-eslint/parser": "5.29.0",
    "eslint": "^8.50.0",
    "jasmine-core": "^4.3.0",
    "jasmine-marbles": "^0.9.2",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "karma-junit-reporter": "^2.0.1",
    "protractor": "~7.0.0",
    "rxjs-marbles": "^7.0.1",
    "ts-node": "^8.8.1",
    "typescript": "^5.5.4"
  },
  "description": "FunFunSpell dictation"
}
