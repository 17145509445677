.vocab-selection-class {
  --background: #fff;
}

.inner-content {
  --backgound: #fff !important;
  background: #fff !important;
}

ion-content {
  --background: #fff;
}

ion-header {
  padding-top: 8px;
  padding-bottom: 8px;
}

.highlight {
  --ion-item-background: #c2dbff !important;
}
