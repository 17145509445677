<ion-card class="card vocab-history-list" style="margin-top: 12px;" *ngIf="vocabs">
  <ion-card-header>
    <ion-card-title>
      <fa-icon [icon]="icon"></fa-icon>
      {{ title | translate }}
      <span *ngIf="infoText"><sup><fa-icon (click)="openInfo()" [icon]="['fas', 'info-circle']"></fa-icon></sup></span>
      ({{'Total'|translate}}: {{vocabs.size}})
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>
    <div class="ion-text-center" *ngIf="vocabs?.size == 0" style="padding-top: 16px;">{{ 'No record' | translate}}</div>
    <ion-list>
      <ion-item *ngFor="let vocab of viewVocabs; let i = index">
        <ion-label>
          <ion-row [@move]="state" (@move.done)="onDone($event)">
            <ion-col class="heading">{{vocab.id.word}}</ion-col>
            <ion-col class="ion-text-right">
              <span class="green-text"><fa-icon [icon]="['fas', 'check']"></fa-icon> {{vocab.correct}}</span>
              /
              <span class="red"><fa-icon [icon]="['fas', 'times']"></fa-icon> {{vocab.wrong}}</span>
            </ion-col>
          </ion-row>
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-row>
      <ion-col>
        <ion-button fill="clear" *ngIf="page > 0" color="secondary" (click)="previous()"><fa-icon [icon]="['fas', 'angle-left']"></fa-icon> {{ 'Previous' | translate }}</ion-button>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-button fill="clear" *ngIf="showNext" color="secondary" (click)="next()">{{ 'Next' | translate }} <fa-icon [icon]="['fas', 'angle-right']"></fa-icon></ion-button>
      </ion-col>
    </ion-row>
    <ion-row style="border-top: 1px solid #c8c7cc;">
      <ion-col class="ion-text-right">
        <ion-button fill="clear" *ngIf="showReview" (click)="onClick('reviewSelected')"><fa-icon icon="pencil-alt"></fa-icon>&nbsp;{{ 'Create Review' | translate }}</ion-button>
        <ion-button fill="clear" *ngIf="showReview" (click)="onClick('reviewAll')"><fa-icon icon="pencil-alt"></fa-icon>&nbsp;{{ 'Start Review' | translate }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-card-content>
</ion-card>
